import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

let isAssigned = false;
let assigned = isAssigned ? "[Asgn.]" : "";

async function getProjectSales({ queries = {}, page = 1, perPage = 30 }) {
    let include = [
        "projectPurchaseReservation",
        "projectPurchaseBooking",
        "projectPurchaseLawyer",
        "projectPurchaseTracking",
        "project",
        "projectUnit",
        "agent",
        "projectUnitFloorPlan",
        "projectUnitType",
        "appUser"
    ];
    try {
        let URL = `${agencyAPI}${isAssigned ? "/my" : ""}/project-purchase`;
        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include.join(",")
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Get All Project Sales)`);
        throw error;
    }
}

async function getTrackingData(id) {
    let include = [
        "projectPurchaseTrackingLogs",
        "projectPurchaseTracking",
        "projectPurchaseReservation",
        "projectPurchaseBooking",
        "projectPurchaseLawyer",
        "project",
        "agent",
        "projectUnit",
        "projectUnitType",
        "projectUnitFloorPlan",
        "appUser",
        "bankLoans.banker"
    ];
    try {
        let URL = `${agencyAPI}${
            isAssigned ? "/my" : ""
        }/project-purchase/bookings/${id}`;
        const response = await axios.get(URL, {
            params: {
                include: include.join(",")
            }
        });

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Get Proj. Tracking Data (ID ${id})`
        );
        throw error;
    }
}

async function cancelProjectSales(id) {
    try {
        const URL = `${agencyAPI}/project-purchase/${id}`;

        const response = await axios.delete(URL);
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Cancel Proj. Sales [ID: ${id}]`
        );
        throw error;
    }
}

async function updateBookingDocuments(id, payload) {
    try {
        let URL = `${agencyAPI}/project-purchase/bookings/${id}/update`;

        const response = await axios.put(URL, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}

async function updateSPADueDate(id, payload) {
    try {
        let URL = `${agencyAPI}/project-purchase/${id}/update-spa-due-date`;

        const response = await axios.put(URL, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}

function getProjectSalesAPI(assigned = false) {
    isAssigned = assigned;
    return {
        getProjectSales,
        getTrackingData,
        cancelProjectSales,
        updateBookingDocuments,
        updateSPADueDate
    };
}

export default {
    getProjectSalesAPI,
    getProjectSales,
    getTrackingData,
    cancelProjectSales
};
