import axios from "axios";
import { API } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

const fileAPI = `${API}/api/common/file-uploader/file`;

export async function uploadFile(file) {
    try {
        const form = new FormData();
        form.append("file", file);

        const request = await axios.post(`${fileAPI}`, form);

        return request.data;
    } catch (error) {
        reportError(error, "Upload File");
        throw error;
    }
}
export default { uploadFile };
