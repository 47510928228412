import axios from "axios";
import { reportError } from "@/utils/error-reporting";

const API = process.env.VUE_APP_API_URL.replace(/\/$/, "");
const AGENCY_API = `${API}/api/agency`;

export async function getBrokenResourceList({ page = 1, perPage = 15 }) {
    try {
        const response = await axios.get(
            `${AGENCY_API}/broken-resource-reports` +
                `?page=${page}&limit=${perPage}`
        );

        return response.data;
    } catch (error) {
        reportError(error, "Get Broken Resource");
        throw error;
    }
}
export async function getBrokenResource(id) {
    try {
        const response = await axios.get(
            `${AGENCY_API}/broken-resource-reports/${id}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `Get Broken Resource [ID: ${id}]`);
        throw error;
    }
}

export async function removeBrokenResource(id) {
    try {
        const response = await axios.delete(
            `${AGENCY_API}/broken-resource-reports/${id}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `Remove Broken Resource [ID: ${id}]`);
        throw error;
    }
}

export default {
    getBrokenResourceList,
    getBrokenResource,
    removeBrokenResource
};
