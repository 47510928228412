import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getAllBankAccounts(
    branchId,
    q = { queries: {}, page: 1, perPage: 30 }
) {
    try {
        let response = await axios.get(`${agencyAPI}/branches/bank-accounts`, {
            params: {
                ...q.queries,
                "agencyBranch:id": branchId,
                page: q.page,
                limit: q.perPage
            }
        });
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[AgencyCP] Get Branch Bank Accounts (id: ${branchId})`
        );
        throw error;
    }
}

async function getBankAccount(id) {
    try {
        let response = await axios.get(
            `${agencyAPI}/branches/bank-accounts/${id}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[AgencyCP] Get Branch Bank Account (accId: ${id})`);
        throw error;
    }
}

async function createBankAccount(payload) {
    try {
        const response = await axios.post(
            `${agencyAPI}/branches/bank-accounts`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[AgencyCP] Create Branch Bank Account`);
        throw error;
    }
}

async function updateBankAccount(id, payload) {
    try {
        const request = await axios.put(
            `${agencyAPI}/branches/bank-accounts/${id}`,
            payload
        );

        return request.data;
    } catch (error) {
        reportError(error, `[AgencyCP] Edit Branch Bank Account (id: ${id})`);
        throw error;
    }
}

async function deleteBankAccount(id) {
    try {
        const response = await axios.delete(
            `${agencyAPI}/branches/bank-accounts/${id}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[AgencyCP] Delete Branch Bank Account (id: ${id})`);
        throw error;
    }
}

export default {
    getAllBankAccounts,
    getBankAccount,
    createBankAccount,
    updateBankAccount,
    deleteBankAccount
};
