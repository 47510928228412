import axios from "axios";
import { commonAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getAuctionTypes() {
    try {
        const response = await axios.get(`${commonAPI}/enum/auction-type`);

        return response.data;
    } catch (error) {
        reportError(error, "[Agency CP] Get Auction Types");
        throw error;
    }
}

export default {
    getAuctionTypes
};
