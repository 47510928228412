import axios from "axios";
import { agencyAPI, commonAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import { downloadBlobFromResponse } from "@/utils/url";

async function getBookings({ queries = {}, page = 1, perPage = 15 }) {
    try {
        let include = ["tenant", "property", "booking", "offerToRent"];

        let response = await axios.get(`${agencyAPI}/property-rentals`, {
            params: {
                ...queries,
                include: include.join(","),
                page: page,
                limit: perPage
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, "[Agency CP] Get Rent Bookings");
        throw error;
    }
}
async function getBooking(id) {
    let include = [
        "tenant",
        "agent",
        "property",
        "payment",
        "booking",
        "offerToRent",
        "ownerSubmission.signatures",
        "applicantSubmission.signatures"
    ];

    try {
        let response = await axios.get(
            `${agencyAPI}/property-rentals/${id}?include=${include}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Rent Booking (ID: ${id})`);
        throw error;
    }
}

async function getTrackingLogs(id) {
    try {
        let response = await axios.get(
            `${agencyAPI}/property-rentals/${id}/tracking-logs`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Rent Tracking Logs`);
        throw error;
    }
}

async function getStatus() {
    try {
        let response = await axios.get(`${commonAPI}/enum/status`, {
            params: {
                statusType: "property_rental"
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Rent Purchase Status`);
        throw error;
    }
}
// =============================== OFFER TO RENT ===============================

async function getOTRData(id) {
    try {
        let response = await axios.get(
            `${agencyAPI}/property-rentals/${id}/otr/detail`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Rent Booking OTR (ID: ${id})`);
        throw error;
    }
}
async function previewOTR(id) {
    try {
        let response = await fetch(
            `${agencyAPI}/property-rentals/${id}/otr/generate-pdf`,
            {
                headers: new Headers({
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("auth_tophillsagency_key"),
                    "Content-Type": "application/x-www-form-urlencoded"
                }),
                method: "POST"
            }
        );
        response.blob().then((data) => window.open(URL.createObjectURL(data)));
    } catch (error) {
        reportError(error, `[Agency CP] Preview Rent OTR (ID: ${id})`);
        throw error;
    }
}
async function updateOTR(id, payload) {
    try {
        let response = await axios.put(
            `${agencyAPI}/property-rentals/${id}/otr/modify`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Update Rent OTR (ID: ${id})`);
        throw error;
    }
}
// ============================= TENANCY AGREEMENT =============================
async function getTenancyAgreement(id) {
    const include = "landLordDetail.users,tenantDetail.users";
    try {
        let response = await axios.get(
            `${agencyAPI}/property-rentals/${id}/tenancy-agreement?include=${include}`
        );

        return response.data === "" ? null : response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get TA (ID: ${id})`);
        throw error;
    }
}

async function getRelationship() {
    try {
        let response = await axios.get(`${commonAPI}/enum/relationship`);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Relationship`);
        throw error;
    }
}
async function getCountries() {
    try {
        const url = "https://restcountries.com/v3.1/all?fields=name";
        let response = await axios.get(url);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get TA Countries`);
        throw error;
    }
}

async function downloadTenancyAgreement(id) {
    try {
        let response = await fetch(
            `${agencyAPI}/property-rentals/${id}/tenancy-agreement/export`,
            {
                headers: new Headers({
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("auth_tophillsagency_key"),
                    "Content-Type": "application/x-www-form-urlencoded"
                }),
                method: "POST"
            }
        );
        let fileName = response.headers
            .get("content-disposition")
            .split("filename=")[1]
            .replace(/^"|"$/g, "");
        downloadBlobFromResponse(response, fileName);
    } catch (error) {
        reportError(error, `[Agency CP] Download TA (ID: ${id})`);
        throw error;
    }
}
async function updateTenancyAgreement(id, payload) {
    try {
        let response = await axios.post(
            `${agencyAPI}/property-rentals/${id}/tenancy-agreement`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Update Rent TA (ID: ${id})`);
        throw error;
    }
}
async function updateTAApplicant(id, payload) {
    try {
        let response = await axios.post(
            `${agencyAPI}/property-rentals/${id}/tenancy-agreement/applicant-submission`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Complete Rent TA Applicant (ID: ${id})`
        );
        throw error;
    }
}
async function updateTAOwner(id, payload) {
    try {
        let response = await axios.post(
            `${agencyAPI}/property-rentals/${id}/tenancy-agreement/owner-submission`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Complete Rent TA Owner (ID: ${id})`);
        throw error;
    }
}
// ============================== BOOKING ACTIONS ==============================

async function completeBooking(id) {
    try {
        let response = await axios.put(
            `${agencyAPI}/property-rentals/${id}/otr/complete`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Complete Rent Booking (ID: ${id})`);
        throw error;
    }
}
async function cancelPurchase(id) {
    try {
        let response = await axios.put(
            `${agencyAPI}/property-rentals/${id}/cancel`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Cancel Rent Booking (ID: ${id})`);
        throw error;
    }
}
async function completePurchase(id) {
    try {
        let response = await axios.put(
            `${agencyAPI}/property-rentals/${id}/complete`
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}
async function requestTenantResubmit(id) {
    try {
        const response = await axios.put(
            `${agencyAPI}/property-rentals/${id}/otr/request-tenant-resubmit`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Rent Request Tenant Resub (ID: ${id})`);
        throw error;
    }
}
async function requestOwnerResubmit(id) {
    try {
        const response = await axios.put(
            `${agencyAPI}/property-rentals/${id}/otr/request-owner-resubmit`
        );

        return response.data;
    } catch (error) {
        `[Agency CP] Rent Request Owner Resub (ID: ${id})`;
        throw error;
    }
}

export default {
    getBookings,
    getBooking,
    getStatus,
    getTrackingLogs,
    //
    getOTRData,
    previewOTR,
    updateOTR,
    //
    getTenancyAgreement,
    getRelationship,
    getCountries,
    downloadTenancyAgreement,
    updateTenancyAgreement,
    updateTAApplicant,
    updateTAOwner,
    //
    completeBooking,
    cancelPurchase,
    completePurchase,
    requestTenantResubmit,
    requestOwnerResubmit
};
