import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import ProjectReservationsModel from "@/models/projectReservations";

let isAssigned = false;
let assigned = isAssigned ? "[Asgn.]" : "";

async function getReservedUnits({ queries = {}, page = 1, perPage = 30 }) {
    let include = "project,projectUnitType,projectUnitFloorPlan,projectUnit";

    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/project-purchase-units`
            : `${agencyAPI}/project-purchase/project-purchase-units`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Get Reserved Units`);
        throw error;
    }
}

async function getReservations(
    projectUnitId,
    {
        queries = {},
        page = 1,
        perPage = 30,
        include = "project,projectUnitFloorPlan,projectUnitType,projectUnit,projectPurchaseReservation,agent"
    }
) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/reservations`
            : `${agencyAPI}/project-purchase/reservations`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include,
                projectUnitId: projectUnitId
            }
        });

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Get Unit Reservations [ID: ${projectUnitId}]`
        );
        throw error;
    }
}

async function getReservationDetail(id) {
    let include =
        "project,projectUnitFloorPlan,projectUnitType,projectUnit,projectPurchaseReservation,agent";
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/reservations/${id}`
            : `${agencyAPI}/project-purchase/reservations/${id}`;

        const response = await axios.get(`${URL}?include=${include}`);

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Get Reservation Detail [ID: ${id}]`
        );
        throw error;
    }
}

async function approveReservation(id, payload) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/reservations/${id}/approved`
            : `${agencyAPI}/project-purchase/reservations/${id}/approved`;

        const response = await axios.put(
            URL,
            ProjectReservationsModel.postApprovePayload(payload)
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Approve Reservation [ID: ${id}]`
        );
        throw error;
    }
}

async function cancelReservation(id) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/${id}`
            : `${agencyAPI}/project-purchase/${id}`;

        const response = await axios.delete(URL);
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Cancel Reservation [ID: ${id}]`
        );
        throw error;
    }
}

function getProjectReservationAPI(assigned = false) {
    isAssigned = assigned;
    return {
        getReservedUnits,
        getReservations,
        getReservationDetail,
        approveReservation,
        cancelReservation
    };
}

export default {
    getReservedUnits,
    getReservations,
    getReservationDetail,
    approveReservation,
    cancelReservation,
    getProjectReservationAPI
};
