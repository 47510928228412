import axios from "axios";
import { commonAPI } from "@/api/config";
import { APIV1 } from "@/modules/api-config";
import { reportError } from "@/utils/error-reporting";

async function getProperty(id) {
    let include =
        "propertyFacilities,propertyAmenities,agencyUser,offerToPurchase,offerToRent,propertyAuction,acnGroup,belongToOwner";
    try {
        let response = await axios.get(
            `${APIV1}/properties/${id}?include=${include}`
        );
        return response.data;
    } catch (error) {
        reportError(error, "[AgencyCP] Get Property");
        throw error;
    }
}
async function getFacilities() {
    try {
        let response = await axios.get(
            `${commonAPI}/property-facilities?limit=100`
        );
        return response.data.data;
    } catch (error) {
        reportError(error, "[AgencyCP] Get Facilities");
        throw error;
    }
}
async function getAmenities() {
    try {
        let response = await axios.get(
            `${commonAPI}/property-amenities?limit=100`
        );
        return response.data.data;
    } catch (error) {
        reportError(error, "[AgencyCP] Get Amenities");
        throw error;
    }
}
async function getPropertyTags() {
    try {
        let response = await axios.get(`${commonAPI}/hashtags?limit=100`);
        return response.data.data;
    } catch (error) {
        reportError(error, "[AgencyCP] Get Hashtags");
        throw error;
    }
}

async function updateProperty(id, payload) {
    try {
        let response = await axios.put(`${APIV1}/properties/${id}`, payload);
        return response.data.data;
    } catch (error) {
        reportError(error, "[AgencyCP] Update Property");
        throw error;
    }
}

export default {
    getFacilities,
    getAmenities,
    getPropertyTags,
    getProperty,
    updateProperty
};
