import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

const STATUS = {
    PENDING: "Vacant Possession Notice",
    COMPLETED: "Completed"
};

let isAssigned = false;
let assigned = isAssigned ? "[Asgn.]" : "";

async function getPendingVPList({ queries = {}, page = 1, perPage = 30 }) {
    let include =
        "project,projectUnit,agent,projectPurchaseReservation,appUser";
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/vacant-possession-list`
            : `${agencyAPI}/project-purchase/vacant-possession-list`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                status: STATUS.PENDING,
                page: page,
                limit: perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Get Pending VP List)`);
        throw error;
    }
}
async function getCompletedVPList({ queries = {}, page = 1, perPage = 30 }) {
    let include =
        "project,projectUnit,agent,projectPurchaseReservation,appUser";
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/vacant-possession-list`
            : `${agencyAPI}/project-purchase/vacant-possession-list`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                status: STATUS.COMPLETED,
                page: page,
                limit: perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Get Completed VP List)`);
        throw error;
    }
}

async function getVPDetail(id) {
    let include =
        "project,projectUnit,agent,projectPurchaseReservation,projectPurchaseLawyer,appUser";
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/bookings/${id}`
            : `${agencyAPI}/project-purchase/bookings/${id}`;

        const response = await axios.get(`${URL}?include=${include}`);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} GetVP Detail (ID ${id})`);
        throw error;
    }
}
async function completeVP(id) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/${id}/completed`
            : `${agencyAPI}/project-purchase/${id}/completed`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Complete VP (ID ${id})`);
        throw error;
    }
}

function getProjectVacantPossessionAPI(assigned = false) {
    isAssigned = assigned;
    return {
        getPendingVPList,
        getCompletedVPList,
        getVPDetail,
        completeVP
    };
}

export default {
    getPendingVPList,
    getCompletedVPList,
    getVPDetail,
    completeVP,
    // Util
    getProjectVacantPossessionAPI
};
