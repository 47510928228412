import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getRefunds({ queries = {}, page = 1, perPage = 30 }) {
    let include = [
        "projectPurchase,projectPurchase.appUser",
        "projectPurchase.projectPurchaseReservation",
        "projectPurchase.project",
        "projectPurchase.projectUnit",
        "projectPurchase.agent"
    ];
    try {
        const response = await axios.get(
            `${agencyAPI}/project-purchase/refunds`,
            {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage,
                    include: include.join(",")
                }
            }
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency] Get P.Refunds`);
        throw error;
    }
}

async function getRefundDetail(id) {
    let include = [
        "projectPurchase,projectPurchase.projectPurchaseReservation",
        "projectPurchase.project",
        "projectPurchase.projectUnit",
        "projectPurchase.agent",
        "projectPurchase.projectUnitType"
    ];

    try {
        const response = await axios.get(
            `${agencyAPI}/project-purchase/refunds/${id}?include=${include.join(
                ","
            )}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency] Get P.Refund Detail [ID: ${id}]`);
        throw error;
    }
}

async function getRejectReason(id) {
    try {
        const response = await axios.get(
            `${agencyAPI}/project-purchase/project-purchase-tracking-log`,
            {
                params: {
                    "projectPurchase:id": id,
                    action: "Refund Rejected"
                }
            }
        );

        return response.data.data[0];
    } catch (error) {
        reportError(error, `[Agency] Get P.Refund Reject Reason [ID: ${id}]`);
        throw error;
    }
}

async function getRefundDeposit(id) {
    try {
        const response = await axios.get(
            `${agencyAPI}/project-purchase/refunds/${id}/deposit`
        );

        return response.data.deposit;
    } catch (error) {
        reportError(error, `[Agency] Get P.Refund Deposit [ID: ${id}]`);
        throw error;
    }
}

async function approveRefund(id, payload) {
    try {
        const response = await axios.put(
            `${agencyAPI}/project-purchase/refunds/${id}/approved`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency] Approve P.Refund [ID: ${id}]`);
        throw error;
    }
}

async function rejectRefund(id, payload) {
    try {
        const response = await axios.put(
            `${agencyAPI}/project-purchase/refunds/${id}/rejected`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `Reject P.Refund [ID: ${id}]`);
        throw error;
    }
}

export default {
    getRefunds,
    getRefundDetail,
    getRefundDeposit,
    getRejectReason,
    approveRefund,
    rejectRefund
};
