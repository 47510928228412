import axios from "axios";
import { agencyAPI, commonAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getExclusiveInfo(propertyId) {
    const include = "property,status";
    try {
        let response = await axios.get(
            `${agencyAPI}/my/properties/${propertyId}/latest-exclusive-request`,
            {
                params: {
                    include: include
                }
            }
        );
        return response.data;
    } catch (error) {
        console.log(error);
        reportError(
            error,
            `[Agency CP] Get Approved Exclusive Info (property: ${propertyId})`
        );
        throw error;
    }
}
async function getApprovedExclusiveInfo(propertyId) {
    const include = "property,status";
    try {
        let response = await axios.get(
            `${agencyAPI}/my/properties/${propertyId}/exclusive`,
            {
                params: {
                    include: include
                }
            }
        );
        return response.data;
    } catch (error) {
        console.log(error);
        reportError(
            error,
            `[Agency CP] Get Exclusive Info (property: ${propertyId})`
        );
        throw error;
    }
}
async function applyExclusive(propertyId, payload) {
    try {
        const response = await axios.post(
            `${agencyAPI}/my/properties/${propertyId}/apply-exclusive`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Apply Exclusive Status (property: ${propertyId})`
        );
        throw error;
    }
}
async function extendExclusive(propertyId, payload) {
    try {
        const response = await axios.post(
            `${agencyAPI}/my/properties/${propertyId}/extend-exclusive`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Apply Exclusive Status (property: ${propertyId})`
        );
        throw error;
    }
}

async function getExclusiveSubmissions(
    q = { queries: {}, page: 1, perPage: 30 }
) {
    const include = "property.agencyUser,status";

    try {
        let response = await axios.get(
            `${agencyAPI}/properties/exclusive-requests`,
            {
                params: {
                    ...q.queries,
                    page: q.page,
                    limit: q.perPage,
                    include: include
                }
            }
        );
        return response.data;
    } catch (error) {
        reportError(error, `[AgencyCP] Get Exclusive Submissions`);
        throw error;
    }
}

async function getExclusiveSubmission(id) {
    const include = "property.agencyUser,status";
    try {
        let response = await axios.get(
            `${agencyAPI}/properties/exclusive-requests/${id}`,
            {
                params: {
                    include: include
                }
            }
        );
        return response.data;
    } catch (error) {
        console.log(error);
        reportError(error, `[Agency CP] Get Exclusive Submission (${id})`);
        throw error;
    }
}

async function approveExclusive(id, payload = {}) {
    try {
        const response = await axios.post(
            `${agencyAPI}/properties/exclusive-requests/${id}/approve`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Approve Exclusive (${id})`);
        throw error;
    }
}

async function rejectExclusive(id, payload = {}) {
    try {
        const response = await axios.post(
            `${agencyAPI}/properties/exclusive-requests/${id}/reject`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Reject Exclusive (${id})`);
        throw error;
    }
}
async function getStatus() {
    try {
        const response = await axios.get(`${commonAPI}/enum/status`, {
            params: {
                statusType: "property_exclusive"
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Status`);
        throw error;
    }
}

export default {
    getExclusiveInfo,
    getApprovedExclusiveInfo,
    applyExclusive,
    extendExclusive,
    // Manage submissions
    getExclusiveSubmissions,
    getExclusiveSubmission,
    approveExclusive,
    rejectExclusive,
    getStatus
};
