import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

let isAssigned = false;
let assigned = isAssigned ? "[Asgn.]" : "";

async function getClients({
    queries = {},
    page = 1,
    perPage = 15,
    include = ""
}) {
    let defaultInclude =
        "agent,projectPurchaseLawyer,projectPurchaseReservation";

    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers`
            : `${agencyAPI}/project-purchase-lawyers`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: `${defaultInclude}${include}`
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Get Purchase Clients`);
        throw error;
    }
}

async function getClient(id) {
    let include =
        "agent,projectPurchaseLawyer,projectPurchaseReservation,projectPurchaseBooking,projectPurchaseMortgage.mortgageMethods.bankLoans";
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers/${id}`
            : `${agencyAPI}/project-purchase-lawyers/${id}`;

        const response = await axios.get(`${URL}?include=${include}`);

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Get Purchase Client (id:${id})`
        );
        throw error;
    }
}

async function setSPADate(id, payload) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers/${id}/select-sign-spa-date`
            : `${agencyAPI}/project-purchase-lawyers/${id}/select-sign-spa-date`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Set SPADate (id: ${id})`);
        throw error;
    }
}
async function setLADate(id, payload) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers/${id}/select-sign-la-date`
            : `${agencyAPI}/project-purchase-lawyers/${id}/select-sign-la-date`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Set LADate (id: ${id})`);
        throw error;
    }
}
async function signSPA(id) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers/${id}/sign-spa`
            : `${agencyAPI}/project-purchase-lawyers/${id}/sign-spa`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Sign SPA (id: ${id})`);
        throw error;
    }
}
async function signLA(id) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers/${id}/sign-la`
            : `${agencyAPI}/project-purchase-lawyers/${id}/sign-la`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Sign LA (id: ${id})`);
        throw error;
    }
}
async function payStampSPA(id, payload) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers/${id}/spa-pay-stamp-duty`
            : `${agencyAPI}/project-purchase-lawyers/${id}/spa-pay-stamp-duty`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Pay Stamp SPA (id: ${id})`);
        throw error;
    }
}
async function payStampLA(id, payload) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers/${id}/la-pay-stamp-duty`
            : `${agencyAPI}/project-purchase-lawyers/${id}/la-pay-stamp-duty`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Pay Stamp LA (id: ${id})`);
        throw error;
    }
}

async function markWithLA(id) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers/${id}/la-confirmed`
            : `${agencyAPI}/project-purchase-lawyers/${id}/la-confirmed`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Mark sale as without LA  (id: ${id})`
        );
        throw error;
    }
}

async function markWithoutLA(id) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase-lawyers/${id}/without-la`
            : `${agencyAPI}/project-purchase-lawyers/${id}/without-la`;

        const response = await axios.put(URL);

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Mark sale as with LA (id: ${id})`
        );
        throw error;
    }
}

function getProjectLawyerAPI(assigned = false) {
    isAssigned = assigned;
    return {
        getClients,
        getClient,
        setSPADate,
        setLADate,
        signSPA,
        signLA,
        payStampSPA,
        payStampLA,
        markWithLA,
        markWithoutLA
    };
}

export default {
    getClients,
    getClient,
    setSPADate,
    setLADate,
    signSPA,
    signLA,
    payStampSPA,
    payStampLA,
    markWithLA,
    markWithoutLA,
    // Util
    getProjectLawyerAPI
};
