import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import qsStringify from "qs/lib/stringify";

async function getReport(id, q = { queries: {} }) {
    try {
        const response = await axios.get(
            `${agencyAPI}/projects/${id}/sales-report`,
            {
                params: {
                    ...q.queries
                }
            }
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Proj. Report (ID: ${id})`);
        throw error;
    }
}
async function downloadReport(id, q = { queries: {} }) {
    try {
        let response = await fetch(
            `${agencyAPI}/projects/${id}/download-sales-report?${qsStringify(
                q.queries
            )}`,
            {
                headers: new Headers({
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("auth_tophillsagency_key"),
                    "Content-Type": "application/x-www-form-urlencoded"
                })
            }
        );
        response.blob().then((data) => window.open(URL.createObjectURL(data)));
    } catch (error) {
        reportError(error, `[Agency CP] Download Proj. Report (ID: ${id})`);
        throw error;
    }
}
async function getUnitsReport(
    projectId,
    q = { queries: {}, page: 1, perPage: 30 }
) {
    try {
        const response = await axios.get(
            `${agencyAPI}/projects/${projectId}/units-sales-report`,
            {
                params: {
                    ...q.queries,
                    page: q.page,
                    limit: q.perPage
                }
            }
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Download Proj. Report (project ID: ${projectId})`
        );
        throw error;
    }
}

export default {
    getReport,
    downloadReport,
    getUnitsReport
};
