import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import ProjectReservationsModel from "@/models/projectReservations";

async function getPrivateReservations({
    queries = {},
    page = 1,
    perPage = 30
}) {
    let include = "projectUnit.project";
    try {
        const URL = `${agencyAPI}/project-units/private-reservation`;

        const response = await axios.get(`${URL}?include=${include}`, {
            params: {
                ...queries,
                page: page,
                limit: perPage
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Private Reservations`);
        throw error;
    }
}

async function getPrivateReservation(reservationId) {
    let include =
        "projectUnit.project,projectUnit.projectUnitFloorPlan,projectUnit.projectUnitType,buyer";
    try {
        const URL = `${agencyAPI}/project-units/private-reservation/${reservationId}`;

        const response = await axios.get(`${URL}?include=${include}`);

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Private Reservation #${reservationId}`
        );
        throw error;
    }
}

async function getBuyerLink(reservationId) {
    try {
        const URL = `${agencyAPI}/project-units/private-reservation/${reservationId}/link`;
        const response = await axios.get(URL);
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Buyer Link (reservation #${reservationId})`
        );
        throw error;
    }
}

async function createPrivateReservation(unitId, payload) {
    try {
        const URL = `${agencyAPI}/project-units/${unitId}/create-private-reservation`;

        const response = await axios.post(
            URL,
            ProjectReservationsModel.postPrivateReservationPayload(payload)
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Create Private Reservation [Unit #${unitId}]`
        );
        throw error;
    }
}

async function updatePrivateReservation(reservationId, payload) {
    try {
        const URL = `${agencyAPI}/project-units/private-reservation/${reservationId}`;

        const response = await axios.put(
            URL,
            ProjectReservationsModel.postPrivateReservationPayload(payload)
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Update Private Reservation #${reservationId}`
        );
        throw error;
    }
}

async function confirmPrivateReservation(reservationId) {
    try {
        const URL = `${agencyAPI}/project-units/private-reservation/${reservationId}/confirm`;

        const response = await axios.put(URL);
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Cancel Private Reservation #${reservationId}`
        );
        throw error;
    }
}

async function cancelPrivateReservation(reservationId) {
    try {
        const URL = `${agencyAPI}/project-units/private-reservation/${reservationId}/cancel`;

        const response = await axios.put(URL);
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Cancel Private Reservation #${reservationId}`
        );
        throw error;
    }
}

export default {
    getPrivateReservations,
    getPrivateReservation,
    getBuyerLink,
    createPrivateReservation,
    updatePrivateReservation,
    confirmPrivateReservation,
    cancelPrivateReservation
};
