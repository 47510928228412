import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import { downloadFile } from "@/utils/download";

let isAssigned = false;
let assigned = isAssigned ? "[Asgn.]" : "";

async function getBookings(status, { queries = {}, page = 1, perPage = 30 }) {
    let include =
        "projectPurchaseReservation,projectPurchaseBooking,agent,projectUnit,projectUnitType,projectUnitFloorPlan,project";
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/bookings`
            : `${agencyAPI}/project-purchase/bookings`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                "projectPurchaseBooking:status": status,
                page: page,
                limit: perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Get P.Bookings (${status})`);
        throw error;
    }
}

async function getBookingDetail(id) {
    let include =
        "projectPurchaseReservation,projectPurchaseBooking,agent,projectUnit,projectUnitType,projectUnitFloorPlan,project,projectPurchaseReservation";

    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/bookings/${id}`
            : `${agencyAPI}/project-purchase/bookings/${id}`;

        const response = await axios.get(`${URL}?include=${include}`);

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Get P.Booking Detail [ID: ${id}]`
        );
        throw error;
    }
}

async function approveBooking(id, payload) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/bookings/${id}/approved`
            : `${agencyAPI}/project-purchase/bookings/${id}/approved`;

        const response = await axios.put(URL, payload);
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Approve P.Booking [ID: ${id}]`
        );
        throw error;
    }
}

async function rejectBooking(id, payload) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/bookings/${id}/rejected`
            : `${agencyAPI}/project-purchase/bookings/${id}/rejected`;

        const response = await axios.put(URL, payload);
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Reject P.Booking [ID: ${id}]`
        );
        throw error;
    }
}

async function cancelBooking(id) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/${id}`
            : `${agencyAPI}/project-purchase/${id}`;

        const response = await axios.delete(URL);
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Cancel P.Booking [ID: ${id}]`
        );
        throw error;
    }
}

async function markAsBookedByOther(id) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/bookings/${id}/booked-by-other`
            : `${agencyAPI}/project-purchase/bookings/${id}/booked-by-other`;

        const response = await axios.put(URL);
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} P.Booking BookedByOther [ID: ${id}]`
        );
        throw error;
    }
}

// ======================= GET REJECTED/CANCELLED REASON =======================
const REJECTED = "Booking Rejected";
const CANCELLED = "Booking Cancelled";

async function getRejectedReason(id) {
    try {
        let data = await getReason(id, REJECTED);
        return data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Get Booking Rejected Reason`
        );
        throw error;
    }
}
async function getCancelledReason(id) {
    try {
        let data = await getReason(id, CANCELLED);
        return data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Get Booking Rejected Reason`
        );
        throw error;
    }
}

async function getReason(id, action) {
    const URL = isAssigned
        ? `${agencyAPI}/my/project-purchase/project-purchase-tracking-log`
        : `${agencyAPI}/project-purchase/project-purchase-tracking-log`;

    const response = await axios.get(URL, {
        params: {
            action: action,
            "projectPurchase:id": id,
            page: 1,
            limit: 1
        }
    });

    return response.data.data[0];
}

function getProjectBookingAPI(assigned = false) {
    isAssigned = assigned;
    return {
        getBookings,
        getBookingDetail,
        approveBooking,
        rejectBooking,
        cancelBooking,
        markAsBookedByOther,
        getRejectedReason,
        getCancelledReason,
        downloadFile
    };
}

export default {
    getBookings,
    getBookingDetail,
    approveBooking,
    rejectBooking,
    cancelBooking,
    markAsBookedByOther,
    getRejectedReason,
    getCancelledReason,
    downloadFile,
    // Util
    getProjectBookingAPI
};
