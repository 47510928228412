import axios from "axios";
import { agencyAPI, commonAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getAuctionRegistrations(
    q = { queries: {}, page: 1, perPage: 30 }
) {
    try {
        const include = "property.agencyUser";
        const response = await axios.get(`${agencyAPI}/property-auction-form`, {
            params: {
                ...q.queries,
                page: q.page,
                limit: q.perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, "[Agency CP] Get Auction Registrations");
        throw error;
    }
}

async function getAuctionRegistration(id) {
    try {
        const include =
            "property.propertyAuction,property.agencyUser,relationship";
        const response = await axios.get(
            `${agencyAPI}/property-auction-form/${id}`,
            {
                params: {
                    include: include
                }
            }
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Auction Registration #${id}`);
        throw error;
    }
}

async function getStatus() {
    try {
        const response = await axios.get(`${commonAPI}/enum/status`, {
            params: {
                statusType: "auction"
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Auction Registration Status`);
        throw error;
    }
}

async function approveAuctionRegistration(id, payload) {
    try {
        const response = await axios.post(
            `${agencyAPI}/property-auction-form/${id}/approve`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Approve Auction Registration #${id}`);
        throw error;
    }
}

async function rejectAuctionRegistration(id, payload) {
    try {
        const response = await axios.post(
            `${agencyAPI}/property-auction-form/${id}/reject`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Reject Auction Types #${id}`);
        throw error;
    }
}

export default {
    getAuctionRegistrations,
    getAuctionRegistration,
    getStatus,
    approveAuctionRegistration,
    rejectAuctionRegistration
};
