import axios from "axios";
import { APIV1 } from "@/modules/api-config";
import { reportError } from "@/utils/error-reporting";

async function getPublicLeads(params = undefined) {
    try {
        return await getLeads("public", params);
    } catch (error) {
        reportError(error, "[Agency CP] Get Public Leads");
        throw error;
    }
}
async function getFollowingLeads(params = undefined) {
    try {
        return await getLeads("agent", params);
    } catch (error) {
        reportError(error, "[Agency CP] Get Following Leads");
        throw error;
    }
}
async function getAgencyLeads(params = undefined) {
    try {
        return await getLeads("agency", params);
    } catch (error) {
        reportError(error, "[Agency CP] Get Agency Leads");
        throw error;
    }
}

async function getLeads(
    subType,
    params = { queries: {}, page: 1, perPage: 30 }
) {
    try {
        const response = await axios.get(`${APIV1}/leads`, {
            params: {
                ...params.queries,
                page: params.page,
                limit: params.perPage,
                collectionType: "agent",
                subType
            }
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

async function getLead(id) {
    try {
        const response = await axios.get(`${APIV1}/leads/${id}?include=agent`);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Lead (#${id})`);
        throw error;
    }
}
async function createLead(payload) {
    try {
        let response = await axios.post(`${APIV1}/leads`, payload);
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Create Lead`);
        throw error;
    }
}
async function followUpLead(id) {
    try {
        let response = await axios.patch(`${APIV1}/leads/${id}/accept`);
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Follow Up Lead (#${id})`);
        throw error;
    }
}

async function dropLead(id, payload) {
    try {
        let response = await axios.patch(`${APIV1}/leads/${id}/drop`, payload);
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Drop Lead (#${id})`);
        throw error;
    }
}

async function createPropertyFromLead(leadId, payload) {
    try {
        let response = await axios.post(
            `${APIV1}/leads/${leadId}/create-property`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Create Property Fr.Lead (#${leadId})`);
        throw error;
    }
}

export default {
    getPublicLeads,
    getFollowingLeads,
    getAgencyLeads,
    getLead,
    createLead,
    followUpLead,
    dropLead,
    createPropertyFromLead
};
