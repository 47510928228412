import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import { ProjectBankLoanModel } from "@/models";

let isAssigned = false;
let assigned = isAssigned ? "[Asgn.]" : "";

async function getBankLoans({
    queries = {},
    page = 1,
    perPage = 15,
    include = [
        "projectPurchaseReservation",
        "projectPurchase.agent",
        "projectUnit"
    ]
}) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/bank-loans`
            : `${agencyAPI}/bank-loans`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include.join(",")
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Get Bank Loans`);
        throw error;
    }
}

async function getBankLoan(id) {
    try {
        let include = [
            "project",
            "projectUnit",
            "loanApplicationDocuments",
            "projectPurchaseReservation",
            "resubmitLoanApplicationDocuments",
            "banker",
            "projectPurchase.agent"
        ];

        const URL = isAssigned
            ? `${agencyAPI}/my/bank-loans/${id}`
            : `${agencyAPI}/bank-loans/${id}`;

        const response = await axios.get(`${URL}?include=${include.join(",")}`);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Get Bank Loan (id: ${id})`);
        throw error;
    }
}

async function verifyLead(id) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/bank-loans/${id}/submitted`
            : `${agencyAPI}/bank-loans/${id}/submitted`;

        const response = await axios.put(URL);
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Verify Lead (id: ${id})`);
        throw error;
    }
}

async function declineLead(id, payload) {
    try {
        payload = ProjectBankLoanModel.putDeclinePayload(payload);

        const URL = isAssigned
            ? `${agencyAPI}/my/bank-loans/${id}/request-additional-doc`
            : `${agencyAPI}/bank-loans/${id}/request-additional-doc`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Decline Lead (id: ${id})`);
        throw error;
    }
}
async function approveLA(id, payload) {
    try {
        payload = ProjectBankLoanModel.putApproveLAPayload(payload);

        const URL = isAssigned
            ? `${agencyAPI}/my/bank-loans/${id}/approved`
            : `${agencyAPI}/bank-loans/${id}/approved`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Approve LA (id: ${id})`);
        throw error;
    }
}

async function rejectLA(id, payload) {
    try {
        payload = ProjectBankLoanModel.putRejectPayload(payload);

        const URL = isAssigned
            ? `${agencyAPI}/my/bank-loans/${id}/rejected`
            : `${agencyAPI}/bank-loans/${id}/rejected`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Reject LA (id: ${id})`);
        throw error;
    }
}

async function confirmLOSigned(id, payload) {
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/bank-loans/${id}/signed-lo`
            : `${agencyAPI}/bank-loans/${id}/signed-lo`;

        const response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Confirmed LO Signed (id: ${id})`
        );
        throw error;
    }
}

// ========================== Other Mortgage Methods ===========================
async function getOtherMethodMortgages(queries = {}, page = 1, perPage = 15) {
    let include = [
        "projectPurchaseMortgage.projectPurchase.projectPurchaseReservation",
        "projectPurchaseMortgage.projectPurchase.agent"
    ];
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/mortgages/other`
            : `${agencyAPI}/project-purchase/mortgages/other`;

        const response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                limit: perPage,
                include: include.join(",")
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP]${assigned} Get Other Method Mortgages`);
        throw error;
    }
}

async function getOtherMethodMortgage(id) {
    let include = [
        "projectPurchaseMortgage.projectPurchase.projectPurchaseReservation",
        "projectPurchaseMortgage.projectPurchase.agent",
        "projectPurchaseMortgage.projectPurchase.project",
        "projectPurchaseMortgage.projectPurchase.projectUnitType",
        "projectPurchaseMortgage.projectPurchase.projectUnitFloorPlan",
        "projectPurchaseMortgage.projectPurchase.projectUnit"
    ];
    try {
        const URL = isAssigned
            ? `${agencyAPI}/my/project-purchase/mortgages/other/${id}`
            : `${agencyAPI}/project-purchase/mortgages/other/${id}`;

        const response = await axios.get(`${URL}?include=${include.join(",")}`);

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP]${assigned} Get Other Method Mortgage (id ${id})`
        );
        throw error;
    }
}

function getProjectMortgageAPI(assigned = false) {
    isAssigned = assigned;
    return {
        // Bank Loans
        getBankLoans,
        getBankLoan,
        verifyLead,
        declineLead,
        approveLA,
        rejectLA,
        confirmLOSigned,
        // Other Methods
        getOtherMethodMortgages,
        getOtherMethodMortgage
    };
}

export default {
    // Bank Loans
    getBankLoans,
    getBankLoan,
    verifyLead,
    declineLead,
    approveLA,
    rejectLA,
    confirmLOSigned,
    // Other Methods
    getOtherMethodMortgages,
    getOtherMethodMortgage,
    // Util
    getProjectMortgageAPI
};
