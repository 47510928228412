import axios from "axios";
import { agencyAPI, commonAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getBookings({ queries = {}, page = 1, perPage = 15 }) {
    try {
        const include = ["buyer", "property", "booking", "offerToPurchase"];

        let response = await axios.get(`${agencyAPI}/subsale-purchases`, {
            params: {
                ...queries,
                include: include.join(","),
                page: page,
                limit: perPage
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, "[Agency CP] Get Subsales Bookings");
        throw error;
    }
}
async function getBooking(id) {
    const include = [
        "buyer",
        "agent",
        "property",
        "payment",
        "booking",
        "ownerSubmission.signatures",
        "applicantSubmission.signatures",
        "offerToPurchase"
    ];

    try {
        let response = await axios.get(
            `${agencyAPI}/subsale-purchases/${id}?include=${include.join(",")}`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Subsales Booking (ID: ${id})`);
        throw error;
    }
}
async function getOTPData(id) {
    try {
        let response = await axios.get(
            `${agencyAPI}/subsale-purchases/${id}/booking/otp/detail`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Subsales Booking OTP (ID: ${id})`);
        throw error;
    }
}

async function getTrackingLogs(id) {
    try {
        let response = await axios.get(
            `${agencyAPI}/subsale-purchases/${id}/tracking-logs`
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Subsales Booking T.Logs (#${id})`);
        throw error;
    }
}

async function getStatus() {
    try {
        let response = await axios.get(`${commonAPI}/enum/status`, {
            params: {
                statusType: "subsale_purchase"
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Subsales Purchase Status`);
        throw error;
    }
}

async function previewOTP(id) {
    try {
        let response = await fetch(
            `${agencyAPI}/subsale-purchases/${id}/booking/otp/generate-pdf`,
            {
                headers: new Headers({
                    Authorization:
                        "Bearer " +
                        localStorage.getItem("auth_tophillsagency_key"),
                    "Content-Type": "application/x-www-form-urlencoded"
                }),
                method: "POST"
            }
        );
        response.blob().then((data) => window.open(URL.createObjectURL(data)));
    } catch (error) {
        reportError(error, `[Agency CP] Preview Subsales OTP (ID: ${id})`);
        throw error;
    }
}
async function updateOTP(id, payload) {
    try {
        let response = await axios.put(
            `${agencyAPI}/subsale-purchases/${id}/booking/otp/modify`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Update Subsales OTP (ID: ${id})`);
        throw error;
    }
}
async function completeBooking(id) {
    try {
        let response = await axios.put(
            `${agencyAPI}/subsale-purchases/${id}/booking/complete`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Complete Subsales Booking (ID: ${id})`);
        throw error;
    }
}
async function cancelPurchase(id) {
    try {
        let response = await axios.put(
            `${agencyAPI}/subsale-purchases/${id}/cancel`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Cancel Subsales Booking (ID: ${id})`);
        throw error;
    }
}
async function completePurchase(id) {
    try {
        let response = await axios.put(
            `${agencyAPI}/subsale-purchases/${id}/complete`
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}
async function requestBuyerResubmit(id) {
    try {
        const response = await axios.put(
            `${agencyAPI}/subsale-purchases/${id}/booking/otp/request-buyer-resubmit`
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Subsales Request Buyer Resub (ID: ${id})`
        );
        throw error;
    }
}
async function requestOwnerResubmit(id) {
    try {
        const response = await axios.put(
            `${agencyAPI}/subsale-purchases/${id}/booking/otp/request-vendor-resubmit`
        );

        return response.data;
    } catch (error) {
        `[Agency CP] Subsales Request Owner Resub (ID: ${id})`;
        throw error;
    }
}

export default {
    getBookings,
    getBooking,
    getOTPData,
    getStatus,
    getTrackingLogs,
    previewOTP,
    updateOTP,
    completeBooking,
    cancelPurchase,
    completePurchase,
    requestBuyerResubmit,
    requestOwnerResubmit
};
