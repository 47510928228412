import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getAllocatedAgents(
    projectId,
    { queries = {}, page = 1, perPage = 30 }
) {
    let include = "agent.agencyBranch";
    try {
        const response = await axios.get(
            `${agencyAPI}/projects/${projectId}/assigned-agents?include=${include}`,
            {
                params: { ...queries, page: page, limit: perPage }
            }
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Allocated Agents [project #${projectId}]`
        );
        throw error;
    }
}

async function getAvailableAgents(
    projectId,
    { queries = {}, page = 1, perPage = 30 }
) {
    let include = "agencyBranch";
    try {
        const response = await axios.get(
            `${agencyAPI}/projects/${projectId}/unassigned-agents?include=${include}`,
            {
                params: { ...queries, page: page, limit: perPage }
            }
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Available Agents [project #${projectId}]`
        );
        throw error;
    }
}

async function allocateNewAgents(projectId, payload) {
    try {
        const response = await axios.post(
            `${agencyAPI}/projects/${projectId}/assign-agent`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Allocate New Agents [project #${projectId}]`
        );
        throw error;
    }
}

async function updateAgents(projectId, payload) {
    try {
        const response = await axios.put(
            `${agencyAPI}/projects/${projectId}/assign-agent`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Update Allocated Agents [project #${projectId}]`
        );
        throw error;
    }
}

async function removeAgents(projectId, payload) {
    try {
        const response = await axios.put(
            `${agencyAPI}/projects/${projectId}/unassign-agent`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Remove Allocated Agents [project #${projectId}]`
        );
        throw error;
    }
}

export default {
    getAllocatedAgents,
    getAvailableAgents,
    allocateNewAgents,
    updateAgents,
    removeAgents
};
