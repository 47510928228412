import axios from "axios";
import { APIV1 } from "@/modules/api-config";
import { reportError } from "@/utils/error-reporting";

async function getGroups(params = { queries: {}, page: 1, perPage: 30 }) {
    try {
        const response = await axios.get(`${APIV1}/acn/groups`, {
            params: {
                ...params.queries,
                page: params.page,
                limit: params.perPage
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get ACN Groups`);
        throw error;
    }
}

async function getGroup(id) {
    try {
        const response = await axios.get(`${APIV1}/acn/groups/${id}`);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get ACN Group (#${id})`);
        throw error;
    }
}
async function getGroupJobs(
    groupId,
    params = { queries: {}, page: 1, perPage: 30 }
) {
    try {
        const response = await axios.get(
            `${APIV1}/acn/groups/${groupId}/jobs`,
            {
                params: {
                    ...params.queries,
                    page: params.page,
                    limit: params.perPage,
                    include: "user.agentProfile"
                }
            }
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get ACN Group Jobs (#${groupId})`);
        throw error;
    }
}

async function getAgencyAgents(
    agencyId,
    params = { queries: {}, page: 1, perPage: 30 }
) {
    try {
        const response = await axios.get(
            `${APIV1}/agencies/${agencyId}/agents`,
            {
                params: {
                    ...params.queries,
                    page: params.page,
                    limit: params.perPage
                }
            }
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Agency Agents (#${agencyId})`);
        throw error;
    }
}

async function assignSelfAsSpecialist(groupId) {
    try {
        const response = await axios.post(
            `${APIV1}/acn/groups/${groupId}/become-specialist`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Assign self as Spc. (#${groupId})`);
        throw error;
    }
}

async function assignCloser(jobId, payload) {
    try {
        const response = await axios.post(
            `${APIV1}/acn/jobs/${jobId}/assign-closer`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Assign Closer (#${jobId})`);
        throw error;
    }
}

async function updateListingCommission(groupId, payload) {
    try {
        const response = await axios.put(
            `${APIV1}/acn/groups/${groupId}`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Edit Commission (#${groupId})`);
        throw error;
    }
}

async function closeGroup(groupId) {
    try {
        const response = await axios.post(
            `${APIV1}/acn/groups/${groupId}/close`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Close Group (#${groupId})`);
        throw error;
    }
}

// =================================== Jobs ===================================
async function getJobScopes() {
    try {
        let response = await axios.get(`${APIV1}/acn/job-scopes`);
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Job Scopes`);
        throw error;
    }
}
async function createJob(groupId, payload) {
    try {
        let response = await axios.post(
            `${APIV1}/acn/groups/${groupId}/jobs`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Create Job (Group:${groupId})`);
        throw error;
    }
}

async function updateJob(jobId, payload) {
    try {
        let response = await axios.put(`${APIV1}/acn/jobs/${jobId}`, payload);
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Update Job (#${jobId})`);
        throw error;
    }
}

async function removeJob(jobId) {
    try {
        let response = await axios.post(`${APIV1}/acn/jobs/${jobId}/drop`);
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Remove Job (#${jobId})`);
        throw error;
    }
}

async function getAvailableJobs(
    type = "normal",
    params = { queries: {}, page: 1, perPage: 30 }
) {
    if (!["specialist", "normal"].includes(type)) {
        throw `Job Type "${type}" is not valid`;
    }

    try {
        let response = await axios.get(`${APIV1}/acn/available-jobs`, {
            params: {
                ...params.queries,
                page: params.page,
                limit: params.perPage,
                jobType: type,
                include: "user,acnQuest,acnGroup"
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Available Jobs (${type})`);
        throw error;
    }
}

async function getJob(jobId) {
    try {
        let response = await axios.get(`${APIV1}/acn/jobs/${jobId}`, {
            params: {
                include:
                    "user,acnQuest.listing.property.agencyUser.user,acnGroup"
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Job Detail (#${jobId})`);
        throw error;
    }
}

async function applyJob(jobId, payload) {
    try {
        let response = await axios.post(
            `${APIV1}/acn/jobs/${jobId}/apply`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Remove Job (#${jobId})`);
        throw error;
    }
}
async function withdrawFromJob(jobId, payload) {
    try {
        let response = await axios.post(
            `${APIV1}/acn/jobs/${jobId}/withdraw`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Withdraw From Job (#${jobId})`);
        throw error;
    }
}

async function markJobAsComplete(jobId, payload) {
    try {
        let response = await axios.post(
            `${APIV1}/acn/jobs/${jobId}/mark-as-completed`,
            payload
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Mark Job as Complete (#${jobId})`);
        throw error;
    }
}

// ============================== Job Application ==============================
async function getJobApplications(
    jobId,
    params = { queries: {}, page: 1, perPage: 1000 }
) {
    try {
        let response = await axios.get(
            `${APIV1}/acn/jobs/${jobId}/applications`,
            {
                params: {
                    ...params.queries,
                    page: params.page,
                    limit: params.perPage,
                    include: "user.agentProfile,acnQuest,acnGroup"
                }
            }
        );
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Job Applications (#${jobId})`);
        throw error;
    }
}

async function getMyJobApplications(
    params = { queries: {}, page: 1, perPage: 30 }
) {
    try {
        let response = await axios.get(`${APIV1}/acn/my-applications`, {
            params: {
                ...params.queries,
                page: params.page,
                limit: params.perPage,
                include: "user,acnQuest,acnGroup"
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get My Job Applications`);
        throw error;
    }
}

async function withdrawJobApplication(applicationId) {
    try {
        let response = await axios.post(
            `${APIV1}/acn/applications/${applicationId}/withdraw`
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Withdraw Job Application (#${applicationId})`
        );
        throw error;
    }
}

async function approveJobApplication(applicationId) {
    try {
        let response = await axios.post(
            `${APIV1}/acn/applications/${applicationId}/approve`
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Approve Application (#${applicationId})`
        );
        throw error;
    }
}

async function rejectJobApplication(applicationId) {
    try {
        let response = await axios.post(
            `${APIV1}/acn/applications/${applicationId}/reject`
        );
        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Reject Application (#${applicationId})`
        );
        throw error;
    }
}

export default {
    getGroups,
    getGroup,
    getGroupJobs,
    getAgencyAgents,
    assignSelfAsSpecialist,
    assignCloser,
    updateListingCommission,
    closeGroup,
    // Jobs
    getJobScopes,
    createJob,
    updateJob,
    removeJob,
    getAvailableJobs,
    getJob,
    applyJob,
    withdrawFromJob,
    // Application
    getJobApplications,
    getMyJobApplications,
    withdrawJobApplication,
    approveJobApplication,
    rejectJobApplication,
    markJobAsComplete
};
